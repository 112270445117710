// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
//require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require("jquery")

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)


import 'bootstrap'

//document.addEventListener("turbolinks:load", () => {
//  $('[data-toggle="tooltip"]').tooltip()
//})

window.jQuery = $;
window.$ = $;

$( document ).ready(function() {
  var youtube = document.querySelectorAll( ".youtube" );
	
  for (var i = 0; i < youtube.length; i++) {
    youtube[i].addEventListener( "click", function() {
      var iframe = document.createElement( "iframe" );
      iframe.setAttribute( "frameborder", "0" );
      iframe.setAttribute( "allowfullscreen", "" );
      iframe.setAttribute( "src", "https://www.youtube.com/embed/"+ this.dataset.embed +"?modestbranding=1&rel=0&showinfo=0&autoplay=1" );
      this.innerHTML = "";
      this.appendChild( iframe );
    } );	
	};
});
	
//document.addEventListener("turbolinks:load", function() {
//    let container = $('#captcha');
//    if (container.length == 1)
//        grecaptcha.render(container[0], {sitekey: '6Lf30OsUAAAAAMipzOrCWKCD-d_Zfgbt2FHOOEml', size: 'invisible', callback: 'callback_captch'});
//});
